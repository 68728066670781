
import { validationMixin } from "vuelidate";
import { required, email, alphaNum, minLength } from "vuelidate/lib/validators";
import { parseJWT } from "@/utils";
import { mapActions } from "vuex";
import { Component, Prop, Vue } from "vue-property-decorator";
import { UFliterals } from "@/utils/literals";
import { UFFormValidationMessage } from "@/utils/literals/forms";
import { statusMessage } from "@/utils/httpUtils";

@Component({
  name: "RegistrationForm",
  mixins: [validationMixin],
  validations: {
    newUser: {
      username: { required, minLength: minLength(8), alphaNum },
      email: { required, email },
      // last_name: { required },
      // first_name: { required },
      password: { required, minLength: minLength(18) },
    },
  },
})
export default class RegistrationForm extends Vue {
  @Prop({ type: String, required: false, default: null })
  token: string | undefined;

  loading = false;
  newUser = {
    username: "",
    password: "",
    first_name: "",
    last_name: "",
    email: "",
  };
  showPassword = false;
  tokenEmail = "";

  created(): void {
    if (this.token) {
      let jwtTokenDetails: { sub: string; exp: number } | null = parseJWT(
        this.token
      );
      if (jwtTokenDetails) {
        this.tokenEmail = jwtTokenDetails.sub;
        this.newUser.email = this.tokenEmail;
      }
      //   FIXME: handle if no token details
    }
  }

  register(): void {
    if (this.newUser.email !== this.tokenEmail) {
      const snack = {
        snackColor: "warning",
        snackText: `Cet email est different que celui precedemment renseigné`,
        snackClosable: true,
        snackTimeOut: 30000,
      };
      this.$store.commit("SET_SNACK", snack);
      this.loading = false;
      return;
    }

    this.loading = true;
    let user = this.newUser;
    user.email = user.email || this.email;
    user.is_active = true;
    user.token_email = this.tokenEmail;

    this.$store.dispatch("user/createUser", {
      user,
      token: this.token,
      errorNext: (error: string) => {
        // If token expired
        if (error.message === statusMessage["401"]) {
          const snack = {
            snackColor: "warning",
            snackText: `Votre lien a expiré, veuillez recommencer depuis le lien reçu par email.`,
            snackClosable: true,
            snackTimeOut: 30000,
          };
          this.$store.commit("SET_SNACK", snack);
          this.loading = false;
          return;
        }
        // Usernane already taken
        if (error.message === statusMessage["400"]) {
          const snack = {
            snackColor: "warning",
            snackText: `Ce nom d'utilisateur est déja utilisé.`,
            snackClosable: true,
            snackTimeOut: 30000,
          };
          this.$store.commit("SET_SNACK", snack);
          this.loading = false;
          return;
        }
      },
      next: () => {
        this.disableForm = false;
      },
    });
  }


  get usernameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.newUser.username.$dirty) return errors;
    !this.$v.newUser.username.minLength &&
      errors.push(this.getUFErrorMessage("minLengthChar")(8));
    !this.$v.newUser.username.required &&
      errors.push(this.getUFErrorMessage("required"));
    !this.$v.newUser.username.alphaNum &&
      errors.push(
        `${this.getUFErrorMessage("alphaNumericFormat")} (Pas d'espace)`
      );
    return errors;
  }

  get emailErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.newUser.email.$dirty) return errors;
    !this.$v.newUser.email.email &&
      errors.push(this.getUFErrorMessage("invalidEmail"));
    !this.$v.newUser.email.required &&
      errors.push(this.getUFErrorMessage("required"));
    return errors;
  }

  // get lastNameErrors(): string[] {
  //   const errors: string[] = [];
  //   if (!this.$v.newUser.last_name.$dirty) return errors;
  //   !this.$v.newUser.last_name.required &&
  //     errors.push(this.getUFErrorMessage("required"));
  //   return errors;
  // }

  get passwordErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.newUser.password.$dirty) return errors;
    !this.$v.newUser.password.minLength &&
      errors.push(this.getUFErrorMessage("minLengthChar")(18));
    !this.$v.newUser.password.required &&
      errors.push(this.getUFErrorMessage("required"));
    return errors;
  }

  // get firstNameErrors(): string[] {
  //   const errors: string[] = [];
  //   if (!this.$v.newUser.first_name.$dirty) return errors;
  //   !this.$v.newUser.first_name.required &&
  //     errors.push(this.getUFErrorMessage("required"));
  //   return errors;
  // }

  get isSubmitDisable(): boolean {
    return this.$v.$invalid || this.loading;
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  getUFErrorMessage(key: string): string {
    return UFFormValidationMessage[key] || "";
  }
}
