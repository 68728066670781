
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import RegistrationForm from "../components/authentication/RegistrationForm.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { UFliterals } from "@/utils/literals";
import { UFFormValidationMessage } from "@/utils/literals/forms";
import { ref } from 'vue';
import message from "@/repository/message";

@Component({
  name: "RegistrationPage",
  components: { RegistrationForm },
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
  methods: {
    ...mapActions("user", ["createUser"]),
  },
})
export default class RegistrationPage extends Vue {
  @Prop({ type: String, required: false, default: null })
  token: string | undefined;

  email = "";
  nom = "";
  prenom = "";
  eafc = "";
  password = "";
  confirmPassword = "";

  pwd=false;
  confirmPwd=false;
  pwdEqual=false;

  show1 = ref(false);
  show2 = ref(false);
  //password = ref();

  min = 8;
  max = 12;

  reg_success = false;

  data(): any {
    return {
      arePwdIdentiq : true,
      error_content:"",
      error_display:false
    }
  }

  @Watch("confirmPassword")
  @Watch("password")
  checkConfPwdInput(): void {
    if(this.confirmPassword!=""){
      if (this.confirmPassword == this.password) {
        this.arePwdIdentiq = true;
      }else{
        this.arePwdIdentiq = false;
      }
    }
  }

  hasLower(text: string): boolean {
    const regex = /[a-z]/;
    this.pwd=this.pwd && regex.test(text);
    return regex.test(text);
  }

  hasUppercase(text: string): boolean {
    const regex = /[A-Z]/;
    this.pwd=this.pwd && regex.test(text);
    return regex.test(text);
  }

  hasSymbol(text: string): boolean {
    const regex = /[!@#$%^&*(),.?":{}|<>]/;
    this.pwd=this.pwd && regex.test(text);
    return regex.test(text);
    //from le back [!@#$%^&*(),.?":{}|<>]
  }

  hasNumber(text: string): boolean {
    const regex = /\d+/;
    this.pwd=this.pwd && regex.test(text);
    return regex.test(text);
  }

  hasSpace(text: string): boolean {
    const regex = /[\s]+/;
    this.pwd=this.pwd && !regex.test(text);
    return regex.test(text);
  }

  hasPWD(text: string): boolean {
    if(text.length>0){
      this.pwd=true
      return(true)
    }
    this.pwd=false
    return false;
  }

  hasPWD2(text: string): boolean {
    if(text.length>0){
      this.confirmPwd=true
      return(true)
    }
    this.confirmPwd=false
    return false;
  }


  rules = {
    required: value => !! this.hasPWD(value) || 'Le mot de passe est requis',
    min: value => value.length >= this.min ||  `Doit contenir au moins ${this.min} caractères`,
    max: v => v.length <= this.max ||  `Doit contenir moins de ${this.max} caractères`,
    pwdLower: value => !! this.hasLower(value) || 'Doit contenir une lettre minuscule',
    pwdUpper: value => !! this.hasUppercase(value) || 'Doit contenir une lettre majuscule',
    pwdSymbol: v => !! this.hasSymbol(v) || 'Doit contenir un caractère spécial : !@#$%^&*(),.?":{}|<> ',
    pwdSpace: v => ! this.hasSpace(v) || "Ne peut pas contenir d'espace",
    pwdNumber: v => !! this.hasNumber(v) || 'Doit contenir au moins un chiffre',
  }

  rules2 = {
    required: value => !! this.hasPWD2(value) || 'Le mot de passe est requis',
  }

  disableForm = false;

  sendRegistrationEmail(): void {
    this.$v.email.$touch();
    this.disableForm = true;
    this.$store.dispatch("getRegistrationEmail", {
      email: this.email,
      prenom: this.prenom,
      nom: this.nom,
      eafc: this.eafc,
      password: this.password,
      next: () => {
        this.reg_success = true;
      },
      errorNext: (error) => {
        this.disableForm = false;
        this.error_display=true
        if(error.message=="400"){
          this.error_content="Un compte existe déjà avec cette adresse e-mail, veuillez vous connecter ou utiliser le formulaire de récuperation de mot de passe."
        }
        else{
          this.error_content="Vous ne pouvez pas vous inscrire. Veuillez contacter l'administrateur de la plateforme."
        }
      },
    });
  }


  get emailErrors(): string[] {
    const errors = [];
    if (!this.$v.email.$dirty) return errors;
    !this.$v.email.email && errors.push(this.getUFErrorMessage("invalidEmail"));
    !this.$v.email.required && errors.push(this.getUFErrorMessage("required"));
    return errors;
  }

  get isSubmitDisable(): any {
   return this.$v.$invalid || this.disableForm || !this.nom || !this.prenom || !this.eafc || !this.pwd || !this.arePwdIdentiq || !this.confirmPwd;
    // return
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  getUFErrorMessage(key: string): string {
    return UFFormValidationMessage[key] || "";
  }
}
